






























import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import BaseButton from './BaseButton.vue';

@Component({
  name: 'EmptyCard',
  components: {
    BaseButton
  }
})
export default class EmptyCard extends Vue {
  @Prop({ default: '' }) readonly title!: string;

  @Prop({ default: '' }) readonly text!: string;

  @Prop({ default: '' }) readonly buttonTitle!: string;

  @Prop({ default: '' }) readonly imageSource!: string;
}
