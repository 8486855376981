










import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import EmptyCard from '@improve/common-components/src/components/widgets/EmptyCard.vue';

@Component({
  name: 'TicketThanks',
  components: {
    EmptyCard
  }
})
export default class TicketThanks extends Vue {
  goToImprove(): void {
    this.$router.push({
      name: 'TicketDetails',
      params: {
        id: this.$route.params?.id
      }
    });
  }
}
